<template>
  <v-container class="px-3 py-6 dialog-header">
    <v-row align="center">
      <v-col class="text-center" cols="8" offset="2">
        <h3 class="dialog-header__title">{{ title }}</h3>
      </v-col>
      <v-col class="text-right" cols="2">
        <v-icon
          style="cursor: pointer"
          color="secondary"
          @click="$emit('close')"
          >mdi-close</v-icon
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DialogHeader",

  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-header {
  background-color: #e8effe;

  &__title {
    color: map-get($colors, "primary");
    font-size: 1.4rem;
  }
}
</style>
