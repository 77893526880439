<template>
  <v-app :class="appClassComponent" :key="key">
    <AppSnackBar />

    <app-bar theme="dark" logo v-if="!hideHeader && isAppBarDisplayed" />
    <VerifyEmailBar
      v-if="this.$store.state.auth.isAuthenticated && authenticationNeeded"
    ></VerifyEmailBar>

    <v-main
      v-if="isRouterViewAvalilable"
      :style="{
        paddingTop: paddingTopCalculated,
        minHeight: '100vh',
      }"
      class="main-container"
      :class="[
        setBuyPagePaddings,
        authenticationNeeded && isAuthenticated && 'authenticationNeeded',
      ]"
    >
      <router-view />
    </v-main>

    <v-main
      v-else
      :class="{
        'admin-users-page': this.$route.fullPath.includes('/admin/users'),
      }"
      :style="{
        paddingTop: paddingTopCalculated,
        minHeight: '100vh',
      }"
    >
      <sub-header v-if="isSideBarDisplayed" />

      <router-view />
    </v-main>

    <app-footer
      v-if="!hideFooter && isAppFooterShown && !hideFooterForMobile"
    />

    <global-loader v-if="$store.state.spinnerShow" />
    <second-global-loader v-if="$store.state.secondSpinnerShow" />

    <v-snackbar
      v-model="subscriptionSnackbar"
      :timeout="subscriptionSnackTimeout"
      top
      right
      multi-line
      :class="IsRoleWasDeclined && 'declined-role-wrapper'"
      class="notification-snack"
    >
      <div
        class="v-snack__timeline"
        :style="{
          width: `${getTimerline}%`,
        }"
      />
      <div class="d-flex subscription-snackbar">
        <div class="image-wrapper">
          <img
            :src="IsRoleWasDeclined ? blackLogo : whiteLogo"
            alt="logo_notifications"
          />
        </div>
        <div class="d-flex flex-column message-wrapper">
          <p
            v-html="subscriptionTextMessage"
            class="mb-0 pa-3 message"
            :class="IsRoleWasDeclined && 'declined-role'"
          ></p>
          <!-- <v-btn
            class="mx-3 mb-3 subscription-snackbar__button"
            @click="updateUserData"
            small
          >
            Apply changes now
          </v-btn> -->
        </div>
      </div>

      <v-btn slot="action" @click="subscriptionSnackbar = false" icon>
        <img src="@/assets/img/icons/close-thin-light.svg" alt="close" />
      </v-btn>
    </v-snackbar>

    <alert-notification-dialog ref="alertNotificationDialog" />
    <update-banking-address-dialog ref="updateBankingAddressDialog"/>
    <alert-dialog ref="alertDialog"/>
    <img
      src="@/assets/img/logo_notifications.svg"
      style="display: none"
      alt="logo_notifications"
    />

    <login-dialog
      @opened="authDialogWasOpened = true"
      @handleRoleChangeSubscription="handleRoleChangeSubscription"
    />

    <register-dialog
      @opened="authDialogWasOpened = true"
      @handleRoleChangeSubscription="handleRoleChangeSubscription"
    />
    <make-offer-dialog />
    <MultiBiddingDialog />
    <profile-seller-edit-dialog
      custom
      :active="isEditSellerDialogOpen"
      :step="sellerEditProfileDialogStep"
      :sellerProfileRedirectToAssetPage="sellerProfileRedirectToAssetPage"
      @setIsDialogOpen="setSellerProfileEditDialog"
      @setStep="(val) => (sellerEditProfileDialogStep = val)"
    />

    <template v-if="isAuthenticated && (isSeller || isAdmin)">
      <AppBankSelection></AppBankSelection>
    </template>

    <template v-if="isAuthenticated">
      <Chat></Chat>

      <WinnerExecution v-if="this.$store.state.user" />
    </template>

    <template v-if="authDialogWasOpened || isAuthenticated">
      <single-asset-offer-dialog />
      <multi-asset-offer-dialog />
      <buyer-profile-edit-dialog />
    </template>

    <template v-if="getUserProfileModal">
      <profile-edit-dialog />
    </template>

    <template v-if="authDialogWasOpened">
      <forgot-password-dialog />
      <resend-code-dialog />
    </template>

    <template v-if="isAuthenticated">
      <VerifyEmailDialog />
    </template>
    <RevokeDialog />
  </v-app>
</template>
<script>
import LoginDialog from "@/components/GlobalDialogs/LoginDialog";
import RegisterDialog from "@/components/GlobalDialogs/RegisterDialog";
import VerifyEmailDialog from "@/components/Auth/VerifyEmailDialog.vue";
import SubHeader from "./components/layout/SubHeader";
import AppBar from "./components/layout/AppBar";
import Profile from "@/services/apiCalls/profile/profile";
import GlobalLoader from "@/components/ui/GlobalLoader";
import SecondGlobalLoader from "@/components/ui/SecondGlobalLoader";
import AppFooter from "@/components/layout/AppFooter";
import subscribeToRoleChange from "@/services/apiCalls/subscriptions/subscribeToRoleChange";
import subscribeDeclineRequest from "@/services/apiCalls/subscriptions/subscribeDeclineRequest";
import subscribeToRoleAssign from "@/services/apiCalls/subscriptions/subscribeToRoleAssign";
import subscribeToRoleRemove from "@/services/apiCalls/subscriptions/subscribeToRoleRemove";
import { Auth } from "aws-amplify";
import AlertNotificationDialog from "@/components/GlobalDialogs/AlertNotificationDialog";
import RevokeDialog from "@/components/GlobalDialogs/RevokeDialog.vue";
import ProfileSellerEditDialog from "@/components/Profile/ProfileSeller/ProfileSellerEditDialog.vue";
import AppSnackBar from "@/components/ui/AppSnackBar.vue";
import AppBankSelection from "@/components/layout/AppBankSelection.vue";
import Chat from "@/components/Chat/Chat.vue";
import { EventBus, EVENTBUS_EVENTS } from "@/eventBus";
import { mapActions, mapGetters, mapState } from "vuex";
import whiteLogo from "@/assets/img/snackbar-logo-light.png";
import blackLogo from "@/assets/img/snackbar-logo-black.png";
import DBInterface from "@/services/localDatabase/DBInterface";
import { LOCAL_DATABASE } from "@/constants/localDatabase";
import { initLocalDatabaseData } from "@/utils/localDatabse/initLocalDatabaseData";
import { deleteDB, openDB } from "idb";
import { getTimestamp } from "@/utils/localDatabse/getTimestamp";
import VerifyEmailBar from "@/components/Common/VerifyEmailBar.vue";
import AlertDialog from "@/components/GlobalDialogs/AlertDialog.vue";
import UpdateBankingAddressDialog from "@/components/GlobalDialogs/UpdateBankingAddressDialog.vue";

export default {
  name: "App",
  components: {
    UpdateBankingAddressDialog,
    AlertDialog,
    Chat,
    VerifyEmailDialog,
    VerifyEmailBar,
    AppBankSelection,
    AppSnackBar,
    LoginDialog,
    RegisterDialog,
    WinnerExecution: () =>
      import("@/components/ClosingDocument/winning/WinnerExecution.vue"),
    BuyerProfileEditDialog: () =>
      import(
        /* webpackChunkName: "BuyerProfileEditDialog" */ "@/components/GlobalDialogs/BuyerProfileEditDialog"
      ),
    ProfileEditDialog: () =>
      import(
        /* webpackChunkName: "ProfileEditDialog" */ "@/components/GlobalDialogs/ProfileEditDialog"
      ),
    MultiAssetOfferDialog: () =>
      import(
        /* webpackChunkName: "MultiAssetOfferDialog" */ "@/components/GlobalDialogs/MultiAssetOfferDialog"
      ),
    SingleAssetOfferDialog: () =>
      import(
        /* webpackChunkName: "SingleAssetOfferDialog" */ "@/components/GlobalDialogs/SingleAssetOfferDialog"
      ),
    ForgotPasswordDialog: () =>
      import(
        /* webpackChunkName: "ForgotPasswordDialog" */ "@/components/GlobalDialogs/ForgotPasswordDialog"
      ),
    ResendCodeDialog: () =>
      import(
        /* webpackChunkName: "ResendCodeDialog" */ "@/components/GlobalDialogs/ResendCodeDialog"
      ),
    SubmitRegisterDialog: () =>
      import(
        /* webpackChunkName: "SubmitRegisterDialog" */ "@/components/GlobalDialogs/SubmitRegisterDialog.vue"
      ),
    MakeOfferDialog: () =>
      import(
        /* webpackChunkName: "MakeOfferDialog" */ "@/components/GlobalDialogs/MakeOfferDialog.vue"
      ),
    MultiBiddingDialog: () =>
      import(
        /* webpackChunkName: "MakeOfferDialog" */ "@/components/GlobalDialogs/MultiBidding/MultiBiddingDialog.vue"
      ),

    // AlertDialog,
    AlertNotificationDialog,
    SubHeader,
    AppBar,
    GlobalLoader,
    SecondGlobalLoader,
    AppFooter,
    RevokeDialog,
    ProfileSellerEditDialog,
  },

  watch: {
    isEditSellerDialogOpen(val) {
      if (!val) {
        this.key = this.key + 1;
      }
    },
    subscriptionSnackbar(val) {
      if (val) {
        this.timeoutId = setInterval(() => {
          this.timer = this.timer - 30;
          if (this.timer <= 0) {
            clearInterval(this.timeoutId);
            this.subscriptionSnackbar = false;
          }
        }, 30);
      }
    },
  },

  async created() {
    EventBus.$on(EVENTBUS_EVENTS.OPEN_LOGIN_DIALOG, async (to) => {
      if (this.$store.state.auth.isAuthenticated) return;
      const res = await this.$root.openLoginDialog({});
      if (res && to) {
        this.$router.push(to.fullPath);
      }
    });
    EventBus.$on(EVENTBUS_EVENTS.OPEN_REGISTER_DIALOG, () => {
      if (this.$store.state.auth.isAuthenticated) return;
      this.$root.openRegisterDialog({});
    });
    // In this case there is a need to disable scrolling
    // while an asset modal is displayed.
    EventBus.$on(EVENTBUS_EVENTS.DISABLE_DIALOG, (value) => {
      const html = document.querySelector("html");

      html.style.overflow = value ? "hidden" : "";
      html.style.height = value ? "100%" : "auto";
    });
    this.$store.dispatch("asset/getAssetsForCarousel");
    this.$store.commit("changeSideBarState", false);
    if (this.$store.state.auth.isAuthenticated) {
      this.handleRoleChangeSubscription();
    }
    this.$root.setSellerProfileEditDialog = this.setSellerProfileEditDialog;

    if (this.$store.state.auth.isAuthenticated) {
      this.$store.dispatch("getCurrentUserProfile");
      this.$store.dispatch("asset/getUserFavoriteAssetsIds");
    }
    this.$store.dispatch("UserSettings/fetchUserSettings");
  },

  async mounted() {
    this.$root.startOfferDialogFlow = this.startOfferDialogFlow;
    this.$root.alert = this.$refs.alertNotificationDialog.open;
    this.$root.updateBankingAddressDialog = this.$refs.updateBankingAddressDialog.open;
    const urlParams = new URLSearchParams(window.location.search);
    const utm_campaign = urlParams.get("utm_campaign");
    if (utm_campaign) {
      localStorage.setItem("utm_campaign", utm_campaign);
    }
    this.$store.dispatch("setHJ");
    const isUserParam = urlParams.get("user");
    if (isUserParam) {
      this.$root.openRegisterDialog({});
      return;
    }
    const isOpenFromUrl = urlParams.get("step");
    if (isOpenFromUrl && !this.$store.state.auth.isAuthenticated) {
      this.$root.openLoginDialog({});
    }
    this.setCountAssets();
    this.setCountOffers();
  },

  data() {
    return {
      subscriptionSnackbar: false,
      subscriptionTextMessage: "",
      authDialogWasOpened: false,
      sellerEditProfileDialogStep: 1,
      sellerProfileRedirectToAssetPage: false,
      key: 0,
      IsRoleWasDeclined: false,
      subscriptionSnackTimeout: 5000,
      whiteLogo,
      blackLogo,
      timer: 5000,
    };
  },

  computed: {
    ...mapGetters(["authenticationNeeded"]),
    ...mapState("UserSettings", ["userSettings"]),
    ...mapState("Helpers", ["hideFooterForMobile"]),
    ...mapGetters([
      "isSeller",
      "isBuyer",
      "isAdmin",
      "user",
      "authenticationNeeded",
    ]),
    setBuyPagePaddings() {
      if (this.$route.path.startsWith("/buy-commercial-real-estate-loans")) {
        return "buy-page";
      }
      return "";
    },
    getUserProfileRequired() {
      const { userProfileRequired = true } = this.userSettings ?? {};

      return userProfileRequired;
    },
    getUserNotificationsCriteriaRequired() {
      const { userNotificationsCriteriaRequired = true } =
        this.userSettings ?? {};

      return userNotificationsCriteriaRequired;
    },
    getUserProfileModal() {
      if (
        Object.keys(this.userSettings).length &&
        (this.authDialogWasOpened || this.isAuthenticated)
      ) {
        return true;
      }
      return false;
    },
    getTimerline() {
      return (this.timer * 100) / 5000;
    },
    isRouterViewAvalilable() {
      const { fullPath } = this.$route;

      return (
        ["/", "/schedule-demo", "/contact"].includes(fullPath) ||
        fullPath.includes("/asset/") ||
        fullPath.includes("/sell-your-loan/") ||
        fullPath.includes("/buy-commercial-real-estate-loans") ||
        /\/#/g.test(fullPath)
      );
    },

    isEditSellerDialogOpen: {
      get() {
        return this.$store.state.isSellerEditDialogOpen;
      },
      set(val) {
        this.$store.commit("setIsSellerEditDialogOpen", val);
      },
    },
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },

    hideFooter() {
      return !!this.$route?.meta?.hideFooter;
    },
    hideHeader() {
      return !!this.$route?.meta?.hideHeader;
    },
    isGreyBackground() {
      return ["/admin/sellersNew", "/admin/buyer-requests"].includes(
        this.$route.fullPath
      );
    },

    appClassComponent() {
      if (this.$route.name) {
        return this.$route.name;
      } else {
        return "OtherPage";
      }
    },
    paddingTopCalculated() {
      if (this.hideHeader) {
        return 0;
      }

      if (
        this.$route.name === "HomePage" ||
        (this.$route.path.startsWith("/sell") &&
          !this.$route.path.startsWith("/sell-your-loan/")) ||
        this.$route.path.startsWith("/choosePlan") ||
        this.$route.path.startsWith("/privacy-policy") ||
        this.$route.path.startsWith("/tos")
      ) {
        return 0;
      } else if (this.$route.path.startsWith("/free-loan-valuation")) {
        if (this.isAuthenticated && this.authenticationNeeded) {
          return "40px";
        }
        return 0;
      } else if (
        this.$route.path.startsWith("/about-xchange-loans") ||
        this.$route.path.startsWith("/loan-sales") ||
        this.$route.path.startsWith("/faq") ||
        this.$route.path.startsWith("/loan-sale-advisory")
      ) {
        if (this.isAuthenticated && this.authenticationNeeded) {
          return "109px";
        }
        return "69px";
      } else if (this.$vuetify.breakpoint.smAndDown) {
        if (this.isAuthenticated && this.authenticationNeeded) {
          return "99px";
        }
        return "59px";
      } else if (this.$vuetify.breakpoint.xlOnly) {
        if (this.isAuthenticated && this.authenticationNeeded) {
          return "113px";
        }
        return "73px";
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        if (this.isAuthenticated && this.authenticationNeeded) {
          return "99px";
        }
        return "59px";
      }
      return "";
    },
    isAppFooterShown() {
      const routes = [
        "/free-loan-valuation",
        "/creationComplete",
        "/successPayment",
      ];
      let isHidden = routes.some((route) =>
        this.$route.fullPath.startsWith(route)
      );
      if (isHidden) {
        return false;
      } else {
        return !this.isSideBarDisplayed;
      }
    },
    isAppBarDisplayed() {
      const routes = [];
      let isHidden = routes.some((route) =>
        this.$route.fullPath.startsWith(route)
      );
      return !isHidden;
    },
    isSideBarDisplayed() {
      // const routes = [ "/seller", "/buyer", "/favorites" , "/valuations", "/creationComplete"];
      const routes = [];

      return routes.some(
        (route) =>
          this.$route.fullPath.startsWith(route) &&
          !this.$route.fullPath.startsWith("/sellerEditAsset") &&
          !this.$route.fullPath.startsWith("/sellerAddAsset") &&
          !this.$route.fullPath.startsWith("/creationComplete")
      );
    },
  },
  methods: {
    ...mapActions("Offers", ["searchOfferByUser"]),
    ...mapActions("asset", ["countSellerAssets"]),
    ...mapActions("Offers", ["countBuyerOffers"]),
    async setCountAssets() {
      if (this.isSeller && this.user) {
        const params = {
          owners: [this.user.id],
        };
        await this.countSellerAssets(params);
      }
    },
    async setCountOffers() {
      if (this.isBuyer && this.user) {
        const params = {
          statuses: [],
          offset: 0,
          size: 10,
        };
        await this.countBuyerOffers(params);
      }
    },
    async deleteExistingDatabase(dbName) {
      try {
        const dbExists = await this.checkIfDatabaseExists(dbName);
        if (dbExists) {
          await deleteDB(dbName);
        }
      } catch (error) {
        console.error("Error deleting database:", error);
      }
    },
    async checkIfDatabaseExists(databaseName, timeout = 5000) {
      // Wrap the openDB call in a Promise
      const openDBPromise = new Promise(async (resolve, reject) => {
        try {
          await openDB(databaseName);
          resolve(true); // Database exists
        } catch (error) {
          resolve(false); // Database does not exist
        }
      });

      try {
        const result = await Promise.race([
          openDBPromise,
          new Promise((_, reject) =>
            setTimeout(() => reject("Timeout"), timeout)
          ),
        ]);
        return result;
      } catch (error) {
        return false; // Database does not exist or operation timed out
      }
    },

    async initLocalDatabase() {
      try {
        const version = getTimestamp();
        const dbInterface = new DBInterface("my-database", version, [
          LOCAL_DATABASE.OFFERS,
          LOCAL_DATABASE.USER_ASSETS,
        ]);

        await dbInterface.init();
        await initLocalDatabaseData(dbInterface, this.$store).catch(
          (err) => {}
        );
        this.$store.commit("setLocalDatabase", dbInterface);
      } catch (err) {
        console.log("INIT DB ERROR", err);
      }
    },
    async startOfferDialogFlow({ options, type }) {
      const buyerProfile = this.$store.getters.user.buyerProfile;
      const isBuyerProfileFilled =
        this.$store.getters.user.buyerProfile &&
        !!this.$store.getters.user.buyerProfile.fundsApprovalDate;
      if (!this.$store.state.auth.user) {
        const isLogged = await this.$root.openLoginDialog({});
        if (!isLogged) {
          return;
        }
      }
      if (!this.$store.state.user.filled.user) {
        const isCompleted = await this.$root.openProfileEditDialog({
          firstComplete: true,
        });
        if (!isCompleted) {
          return;
        }
      }
      if (!isBuyerProfileFilled) {
        await this.$root.openBuyerProfileEditDialog({
          firstComplete: true,
        });

        return;
      }

      if (buyerProfile.fundsApprovalDate) {
        const expirationDate = this.$moment(
          buyerProfile.fundsApprovalExpiryDate
        );

        const isExpired = this.$moment().diff(expirationDate) > 0;
        if (isExpired) {
          const isCompleted = await this.$root.openBuyerProfileEditDialog({
            firstComplete: true,
            step: 3,
            showExpiredFundsMessage: true,
          });
          if (!isCompleted) {
            return;
          }
        }
      }

      if (!this.$store.state.user.roles.includes("ApprovedBuyers")) {
        this.$root.alert({
          content:
            "Your Buyer Role request is under review. You will be notified via email about its status.<br>" +
            "Once approved you can make an offer.",
        });
        return;
      }
      if (buyerProfile.fundsApprovalDate) {
        const expirationDate = this.$moment(buyerProfile.fundsApprovalDate).add(
          90,
          "days"
        );
        const isExpired = this.$moment().diff(expirationDate) > 0;
        if (!isExpired) {
          if (type === "single") {
            this.$root.openSingleAssetOfferDialog(options);
          } else {
            this.$root.openMultiAssetOfferDialog(options);
          }
        }
      }
    },
    subscribeApproveBuyerRequest() {
      const sub = subscribeToRoleChange().subscribe({
        next: async (data) => {
          this.IsRoleWasDeclined = false;
          this.timer = 5000;

          const roleData = data.value.data.subscribeToRoleChange;
          const userSub =
            this.$store.state?.auth?.user && this.$store.state.auth?.user?.sub;
          // eslint-disable-next-line no-console
          console.log("subscribeApproveBuyerRequest", roleData);
          if (roleData.creator === userSub) {
            if (roleData.status === "Approved") {
              if (roleData.type === "Sellers") {
                this.subscriptionTextMessage =
                  "Your request for the Seller role has been approved. The changes can be applied now or after your next login.";
              }

              if (roleData.type === "ApprovedBuyers") {
                this.subscriptionTextMessage =
                  "Your request for the Buyer role has been approved.<br/> The permission to place offers is granted.";
              }

              if (this.subscriptionTextMessage) {
                this.subscriptionSnackbar = true;
              }
            }
            setTimeout(async () => {
              await this.updateUserData();
            }, 5000);
          }
        },
        // eslint-disable-next-line no-console
        error: (error) => console.log(error),
      });
      this.$store.commit("subscribeRoleChange", sub);
    },
    subscribeDeclineBuyerRequest() {
      const sub = subscribeDeclineRequest().subscribe({
        next: async (data) => {
          this.IsRoleWasDeclined = true;
          this.timer = 5000;
          const roleData = data.value.data.subscribeToDeclineBuyerRequest;
          const userSub =
            this.$store.state.auth?.user && this.$store.state.auth?.user?.sub;
          // eslint-disable-next-line no-console
          console.log("subscribeDeclineBuyerRequest", roleData);

          if (roleData.creator === userSub) {
            this.subscriptionTextMessage =
              "Unfortunately, your Proof of Funds request was declined.<br/> Please review the information you had provided and try again.";
            this.subscriptionSnackbar = true;
            setTimeout(async () => {
              await this.updateUserData();
            }, 5000);
          }
        },
        // eslint-disable-next-line no-console
        error: (error) => console.log(error),
      });
      this.$store.commit("subscribeRoleChange", sub);
    },
    subscribeRoleAssign() {
      // eslint-disable-next-line no-unused-vars
      const sub = subscribeToRoleAssign().subscribe({
        next: async (data) => {
          const userSub =
            this.$store.state.auth?.user && this.$store.state.auth?.user?.sub;
          const subscribtionUserId = data.value.data.subscribeToRoleAssign.id;
          // eslint-disable-next-line no-console
          console.log("subscribeRoleAssign", userSub);
          if (subscribtionUserId === userSub) {
            this.subscriptionTextMessage =
              "You have been assigned a Buyer role.<br/>You can now make offers.";
            this.subscriptionSnackbar = true;
            setTimeout(async () => {
              await this.updateUserData();
            }, 5000);
          }
        },

        // eslint-disable-next-line no-console
        error: (error) => console.log(error),
      });
    },
    subscribeRoleRemove() {
      // eslint-disable-next-line no-unused-vars
      const sub = subscribeToRoleRemove().subscribe({
        next: async (data) => {
          const userSub =
            this.$store.state.auth?.user && this.$store.state.auth?.user?.sub;
          const subscribtionUserId = data.value.data.subscribeToRoleRemove.id;
          // eslint-disable-next-line no-console
          console.log("subscribeRoleAssign", userSub);
          if (subscribtionUserId === userSub) {
            this.subscriptionTextMessage =
              "Your Buyer permission was removed.<br/>You can no longer make offers.";
            this.subscriptionSnackbar = true;
            setTimeout(async () => {
              await this.updateUserData();
            }, 5000);
          }
        },

        // eslint-disable-next-line no-console
        error: (error) => console.log(error),
      });
    },

    handleRoleChangeSubscription() {
      // eslint-disable-next-line no-console
      console.log("handleRoleChangeSubscription");
      try {
        this.subscribeApproveBuyerRequest();
        this.subscribeDeclineBuyerRequest();
        this.subscribeRoleAssign();
        this.subscribeRoleRemove();

        if (this.$store.state.user) {
          this.initLocalDatabase();
        }
      } catch (err) {
        console.log("app sub err ", err);
      }
    },
    setSellerProfileEditDialog(val, redirectToAssetPage = true) {
      this.isEditSellerDialogOpen = val;
      this.sellerProfileRedirectToAssetPage = redirectToAssetPage;
    },
    async updateUserData() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        const currentSession = await Auth.currentSession();
        console.log("UPDATE USER DATA");
        currentUser.refreshSession(currentSession.refreshToken, async () => {
          const currentUpdatedUser = await Auth.currentAuthenticatedUser();

          this.$store.commit("setAuthState", {
            isAuthenticated: true,
            user: currentUpdatedUser.attributes,
          });

          localStorage.setItem(
            "currentUser",
            JSON.stringify(currentUpdatedUser.attributes)
          );

          this.$store.dispatch("getCurrentUserProfile");

          this.subscriptionSnackbar = false;
        });
      } catch (err) {
        console.error(err);
      }
    },
    async getProfile() {
      try {
        const currentUserProfile = await Profile.getProfile();
        if (currentUserProfile) {
          this.$store.commit("setUser", {
            user: currentUserProfile.data.getUserProfile,
          });
        }
      } catch (err) {
        console.error("err", err);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./styles/global-styles.scss";

@import "./styles/overridings.scss";
@import "./styles/globalclasses.scss";
.background-grey {
  background: #fcfcfc;
}

.message-wrapper .message {
  padding-top: 0 !important;
}
.v-snack__action {
  top: 6px;
}
.subscription-snackbar {
  min-height: 86px;
  & .image-wrapper {
    height: 34px;
    width: 32px;
    display: flex;
    align-self: center;
    & img {
      width: 100%;
      height: 100%;
    }
  }
  &__button {
    background: #2969ff !important;
    border-radius: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    max-width: 250px;
  }
}
.declined-role {
  color: #d52819 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.declined-role-wrapper {
  .subscription-snackbar {
    background: white;
    min-height: 104px;
  }
  .image-wrapper {
    display: flex;
    align-self: center;
  }
  .v-snack__action {
    top: 6px;
    img {
      filter: invert(100%);
    }
  }
}
.v-snack__timeline {
  position: absolute;
  top: 0px;
  height: 2px;
  background: #dd226f;
}
iframe[src="https://webagent.zentinel.app/"],
iframe[src="https://test-zentinel.prettyfluid.com"],
iframe[src="https://stage-webagent.zentinel.app/"]
{
  position: absolute;
  z-index: -999;
}
::v-deep {
  .cursor-pointer {
    cursor: pointer;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .notification-snack > div {
    min-width: auto !important;
  }
}

@media screen and (max-width: 581px) {
  .buy-page.authenticationNeeded {
    padding-top: 83px !important;
  }
}
</style>
