<template>
  <v-dialog
    v-model="dialog"
    elevation="10"
    width="720" persistent
    @keydown.esc="close"
  >

    <v-card class="d-flex align-center justify-center card-wrapper">
      <div class="close-icon-wrapper">
        <v-icon @click="close"> mdi-close </v-icon>

      </div>

      <div class="card-content">
        <div class="d-flex justify-center">
          <v-img
            src="@/assets/img/logo_black.svg"
            alt="Xchange.Loans"
            class="message-admin-dialog__logo"
            height="50"
            max-width="248"
          />
        </div>
        <div class="header-title">
          Action Required: Add Your Banking Address
        </div>
        <div class="card-text">
          <div>
            Our payment processing provider requires your linked bank account to have an address.
          </div>
          <div>
            Until this is updated, they are unable to accept your offers.

          </div>
          <div>
            To continue bidding, please update your banking details by clicking the <br/> button below.

          </div>
          <div>
            For additional assistance, contact our
            <span @click="openContactUs" class="support-team-text">
              support team.
            </span>

          </div>
        </div>
        <div class="button-wrapper">
          <v-btn
            color="#2969FF"
            width="230px"
            height="40px"
            class="button-second next-button c-button"
            shaped
            rounded
            data-test-id="submit-offer-btn"
            @click="onButtonClick"
          >
            <div class="update-banking-text">
              Update Banking Details

            </div>
          </v-btn>
        </div>

      </div>
    <div>

    </div>

    </v-card>

    <ContactUsDialog ref="contactUsDialog"></ContactUsDialog>

  </v-dialog>
</template>

<script>
import DialogHeader from "@/components/Common/DialogHeader.vue";
import ContactUsDialog from "@/components/GlobalDialogs/ContactUsDialog.vue";

export default {
  name: "UpdateBankingAddressDialog",
  components: { ContactUsDialog, DialogHeader },
  data() {
    return {
      dialog: false,
      resolve: null,
      content: null,
      title: null,
      onClick:null
    };
  },

  methods: {
    open(onClick) {
      console.log('onClick' , onClick);
      this.dialog = true;
      this.onClick = onClick
    },
    close() {
      this.dialog = false;
    },
    onButtonClick(){
      this.onClick();
      this.dialog = false;
    },
    openContactUs() {
      this.$refs.contactUsDialog.open();

    },
  }
};
</script>

<style lang="scss" scoped>
.notification-alert {
  position: fixed;
  top: 10vh;
  right: 20px;
  z-index: 999;
  width: 100%;
  max-width: 500px;
  @media (max-width: 960px) {
    right: 0;
  }

  &.rounded {
    border-radius: 8px !important;
  }
}

.logo {
  width: 100%;
  max-width: 45px;
}

.card-content {
  max-width: 573px;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.header-title {
  font-family: Epilogue;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.32px;
  color: #001154;
  margin-top: 16px;
  white-space: nowrap;
  text-align: center;
}

.card-text {
  font-family: Epilogue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 128.571% */
  color: #121727;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  opacity: 0.8;
  width: 100%;
}
.update-banking-text{
  color: white;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
}

.card-wrapper {
  border-radius: 7px;
  position: relative;
}

.button-second{
  min-height: unset !important;
  margin: 40px auto 0 !important;
}
.button-wrapper{
  display: flex;
}
.close-icon-wrapper{
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.support-team-text{
  color:#2969FF;
  cursor: pointer;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
 .header-title{
   white-space: pre-wrap;
 }
}
</style>
