<template>
  <v-dialog v-model="dialog" width="440" persistent @keydown.esc="close">
    <v-card>
      <dialog-header :title="title" @close="close" />
      <v-card-text class="pt-8" v-html="content" />

      <v-card-actions class="d-flex justify-end">
        <v-btn color="secondary" width="80" small height="56" @click="close">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from "@/components/Common/DialogHeader";

export default {
  name: "AlertDialog",
  components: { DialogHeader },
  data() {
    return {
      dialog: false,
      resolve: null,
      content: null,
      title: null,
    };
  },

  methods: {
    open({ title, content }) {
      this.content = content;
      this.title = title;
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    close() {
      this.text = null;
      this.content = null;
      this.title = null;
      this.dialog = false;
      this.resolve();
    },
  },
};
</script>

<style scoped></style>
