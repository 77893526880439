<template>
  <v-dialog
    v-model="dialogModel"
    :attach="attach"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :hide-overlay="hideOverlay"
    content-class="error-msg"
    class="conflict-bank-msg"
    width="600"
    persistent
    @keydown.esc="dialogModel = false"
  >
    <v-card>
      <v-card-title class="card-title pt-10 justify-center">
        {{ errorTitle }}
      </v-card-title>
      <v-card-text class="err-text">
        {{ errorText }}
      </v-card-text>
      <v-card-text v-if="bonusText" class="err-text">
        {{ bonusText }}
      </v-card-text>
      <v-card-actions class="justify-center pb-10">
        <v-btn class="button-second" height="34px" width="172px" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    attach: {
      type: String,
      default: "",
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    errorTitle: {
      type: String,
      default: "Oops, there was an error",
    },
    bonusText: {
      type: String,
      default: "",
    },
    errorText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogModel: true,
    };
  },
  watch: {
    dialogModel: {
      handler(val) {
        this.$emit("changeDialogStatus", val);
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.dialogModel = true;
    },
    close() {
      this.dialogModel = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-second {
  color: #ffffff;
  border-radius: 7px;
  background: #2969ff !important;
  font-size: 14px !important;
  min-height: auto !important;
}
.err-text {
  font-size: 16px;
  color: #001154 !important;
  font-weight: 500;
}
::v-deep {
  .error-msg {
    border-radius: 7px;
    box-shadow: 4px 5.33333px 20px rgba(0, 17, 84, 0.14);
  }
}
</style>
